import React from 'react'


function Header() {
    return(
    <div>
        <header className="App-header">
            <div>
                <h1>Anywhere Fitness</h1>
            </div>
            <div>
                <h4>Trainers</h4>
            </div>

        </header>
    </div>
    )
}

export default Header